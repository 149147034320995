import {Animate, Button, Flex, Paper, SnackbarService, String} from "@hps/hops-react";
import React, {useEffect, useState} from "react";

import CheckoutCustomerEmailForm from "Checkout/CustomerDetails/CheckoutCustomerEmailForm.js";
import GatewayService from "Gateway/GatewayService.js";
import withGateway from "Hoc/withGateway.js";
import withReducedFunctionality from "Hoc/withReducedFunctionality.js";
import withSettings from "Hoc/withSettings.js";
import OrderService from "Services/OrderService.js";

import ReceiptUtils from "./ReceiptUtils.js";

import CheckCircle from "@mui/icons-material/CheckCircle";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import PrintIcon from "@mui/icons-material/PrintOutlined";

/**
 * Receipt UI component
 * 
 * @package HOPS
 * @subpackage Receipts
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
const ReceiptUi = props => {

	/**
	 * Ref to our `iframe`
	 * 
	 * @type {ReactRef}
	 */
	let iframeRef = React.createRef();

	const [customerEmailInputDialogOpen, setCustomerEmailInputDialogOpen] = useState(false);
	const [emailing, setEmailing] = useState(false);
	const [hasEmailed, setHasEmailed] = useState(false);
	const [printing, setPrinting] = useState(false);
	const [svg, setSvg] = useState(null);
	const [receiptMarkup, setReceiptMarkup] = useState(null);
	const orderIdentity = props.PaymentOutcome.Order;
	const billingEmail = props.PaymentOutcome.CustomerDetails?.Email;

	/**
	 * Width of the receipt preview (px)
	 *
	 * @type {Number}
	 */
	const previewWidth = 320;

	/**
	 * Receipt preview container styles.
	 * 
	 * @type {Object}
	 */
	const receiptPreviewContainerStyles = {
		height: "100%",
		margin: "0 auto"
	};

	/**
	 * Receipt preview container inner styles.
	 *
	 * @type {Object}
	 */
	const receiptPreviewContainerInnerStyles = {
		display: "flex",
		justifyContent: "center",
		height: "100%",
		maxHeight: `calc(100vh - 32rem)`,
		margin: "0 auto",
		overflowY: "auto",
		width: `calc(${previewWidth}px + 4rem)`
	};

	useEffect(() => {

		const cpl = props.Settings.ReceiptsCpl;

		const markup = ReceiptUtils.createReceipt(props.PaymentOutcome, cpl, props.Reprint, props.KickCashDrawer);
		setReceiptMarkup(markup);

		const generatedSvg = ReceiptUtils.getSvg(markup, cpl);
		setSvg(generatedSvg);

	}, [props.KickCashDrawer, props.PaymentOutcome, props.Reprint, props.Settings.ReceiptsCpl]);


	/**
	 * Receipt `iframe` loaded.
	 *
	 * We update its styles to match our layout.
	 *
	 * @return {void}
	 */
	const handleIframeLoad = () => {
		if (iframeRef) {

			const svg = iframeRef.contentDocument.querySelector("svg");
			const svgWidth = svg.width.baseVal.value;
			const svgHeight = svg.height.baseVal.value;
			const svgAspect = (svgHeight / svgWidth);

			/**
			 * We are limiting the rendering width to `constructor.previewWidth`
			 *
			 * We need to adjust the SVG's rendering height to by the same 
			 * ratio as we've reduced the width by relative to intrinsic width, 
			 * otherwise we'd have a massive overflow below the end of the 
			 * actual SVG content.
			 */
			const targetWidth = previewWidth;
			const targetHeight = Math.ceil((svgAspect * targetWidth));

			/**
			 * `<style>` tag to inject into the `iframe` to restyle the SVG
			 *
			 * We need to use the SVG's intrinsic dimensions (aligned with 
			 * common receipt dimensions) when we're printing!
			 */
			const svgStyles = `<style>
				@media not print {
					svg {
						width: ${targetWidth}px;
						height: ${targetHeight}px;
					}
				}
			</style>`;

			/**
			 * Update all relevant styles now
			 */
			iframeRef.setAttribute("width", `${targetWidth}px`);
			iframeRef.setAttribute("height", `${targetHeight}px`);
			iframeRef.contentDocument.body.innerHTML += svgStyles;
			iframeRef.contentDocument.body.style.margin = 0;
			iframeRef.contentDocument.body.style.overflow = "hidden";

			/**
			 * We want to print the receipt without user input
			 */
			if (props.Settings.AutomaticReceiptPrinting && !(props.DisableAutomaticPrinting)) {
				handlePrint();
			}

		}
	};


	/**
	 * Received a new `iframe` `ref`.
	 *
	 * @param {ReactRef} ref
	 * @return {void}
	 */
	const handleNewIframeRef = ref => (iframeRef = ref);


	/**
	 * Print the receipt.
	 *
	 * @async
	 * @return {void};
	 */
	const handlePrint = async () => {
		if (svg && iframeRef) {
			try {

				setPrinting(true);

				if (props.gateway.Active && props.gateway.ReceiptsEnabled) {

					try {
						const printResult = await GatewayService.printReceipt(receiptMarkup, props.Settings.ReceiptsCpl);

						switch (printResult?.result) {
							case "online":
								SnackbarService.snack("Receipt Printing Error: Printer is Online", "error");
								break;
							case "coveropen":
								SnackbarService.snack("Receipt Printing Error: Cover is open", "error");
								break;
							case "paperempty":
								SnackbarService.snack("Receipt Printing Error: Out of paper", "error");
								break;
							case "error":
								SnackbarService.snack("Receipt Printing Error: General Print Error", "error");
								break;
							case "offline":
								SnackbarService.snack("Receipt Printing Error: Printer is offline", "error");
								break;
							case "disconnect":
								SnackbarService.snack("Receipt Printing Error: Printer is disconnected", "error");
								break;
							case "timeout":
								SnackbarService.snack("Receipt Printing Error: Timeout connecting to printer", "error");
								break;
							default:
								// Doesn't handle "success" - no message
						}
					}
					catch (e) {

						let msg = e;
						if (!e?.response) {
							msg = "Connectivity error (check that the Gateway server is running).";
						}

						SnackbarService.snack(`Receipt Printing Error: ${msg}`, "error");

					}

				}
				else iframeRef?.contentWindow?.print?.();

				setPrinting(false);

			}
			catch (e) {

				/**
				 * We're catching in case of e.g. future browser 
				 * changes to prevent printing without a user 
				 * interaction which could break the automatic flow
				 */

			}
		}
	};


	/**
	 * E-mail button clicked.
	 */
	const handleEmailClick = async () => {

		if (billingEmail) {
			await handleSendReceiptEmail(orderIdentity, billingEmail);
		}
		else {
			setCustomerEmailInputDialogOpen(true);
		}

	};

	/**
	 * E-mail the receipt
	 */
	const handleSendReceiptEmail = async customerEmail => {

		setEmailing(true);

		try {

			await OrderService.sendEmailConfirmation(orderIdentity, customerEmail);
			SnackbarService.snack(`Receipt Email queued for delivery`, "success");
			setHasEmailed(true);

		}
		catch (e) {

			let msg = e;
			if (e?.response?.status === 404) {
				msg = "Receipt Email Error: No Billing Email specified.";
			}

			SnackbarService.snack(`Receipt Email Error: ${msg}`, "error");

		}

		setEmailing(false);

	};


	/**
	 * Render the receipt `iframe`.
	 * 
	 * @return {ReactNode}
	 */
	const renderReceipt = () => {
		return (
			<div style={receiptPreviewContainerInnerStyles}>
				<iframe
					srcDoc={svg}
					onLoad={handleIframeLoad}
					ref={handleNewIframeRef}
					title="Customer Receipt" />
			</div>
		);
	};


	/**
	 * Email input dialog closed.
	 * 
	 * @return {void}
	 */
	const handleCustomerEmailInputDialogClose = () => setCustomerEmailInputDialogOpen(false);


	/**
	 * Email input dialog submitted.
	 * 
	 * @return {void}
	 */
	const handleCustomerEmailInputDialogSubmit = email => {
		setCustomerEmailInputDialogOpen(false);
		handleSendReceiptEmail(email);
	};


	/**
	 * Render the customer email input dialog.
	 * 
	 * @return {ReactNode}
	 */
	const renderCustomerEmailInputDialog = () => {
		return (
			<CheckoutCustomerEmailForm
				onClose={handleCustomerEmailInputDialogClose}
				onSubmit={handleCustomerEmailInputDialogSubmit}
				open={customerEmailInputDialogOpen} />
		);
	};


	return (
		<Flex>
			<Flex
				alignItems="flex-start"
				columnar={true}
				flexWrap={true}
				gap={2}
				justifyContent="center">
				<Button
					disabled={printing}
					label={props.Settings.AutomaticReceiptPrinting && !(props.DisableAutomaticPrinting) ? "Re-Print" : "Print"}
					onClick={handlePrint}
					size="large"
					startIcon={PrintIcon} />
				{/* This is allowed enabled for Bodmin / Cornish Cafes to test */}
				<Button
					disabled={(emailing || hasEmailed || (!props.AlwaysOffline && props.ReducedFunctionality))}
					label="E-Mail"
					onClick={handleEmailClick}
					size="large"
					startIcon={EmailIcon} />
			</Flex>
			{
				(hasEmailed &&
						<Flex
							alignItems="center"
							gap={0}>
							<Animate
								animation="pop"
								component={CheckCircle}
								componentProps={{color: "error"}}
							/>
							<String
								bold={true}
								color="error"
								centre={true}
								str="Receipt Email queued for delivery." />
						</Flex>
				)
			}
			<div style={receiptPreviewContainerStyles}>
				<Paper outlined={true}>
					{(svg && renderReceipt())}
				</Paper>
			</div>
			{(customerEmailInputDialogOpen && renderCustomerEmailInputDialog())}
		</Flex>
	);

};

export default withGateway(withReducedFunctionality(withSettings(ReceiptUi)));
