import {Banner, BreadcrumbBar, EmptyStateGate, Flex, Hidden, Navigator, Paper, String, withMobile, withRegistration} from "@hps/hops-react";
import {CheckoutBasketItem, OrderableTypes} from "@hps/hops-sdk-js";
import {Api} from "@hps/hops-sdk-js";
import Pagination from "@mui/material/Pagination";
import React, {useRef, useState} from "react";
import {useParams} from "react-router-dom";

import withBasket from "Hoc/withBasket.js";
import withInventory from "Hoc/withInventory.js";
import withReducedFunctionality from "Hoc/withReducedFunctionality";
import InventoryGrid from "Inventory/InventoryGrid.js";
import InventoryGridItemFolder from "Inventory/InventoryGridItemFolder";
import InventoryGridTileTypes from "Inventory/InventoryGridTileTypes";
import BasketService from "Services/BasketService.js";

import InventoryGridItemProduct from "./InventoryGridItemProduct";

import ArrowBack from "@mui/icons-material/ArrowBack";

/**
 * Product inventory browser
 *
 * Renders available org products.
 * 
 * @package HOPS
 * @subpackage Inventory
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
const ProductInventoryBrowser = props => {

	const {BarcodeString} = useParams();

	/**
	 * Find the Default Folder specified in registration
	 * Fall back to any folder with no parent
	 */
	const rootFolder = props.Registration?.Folders.find(f => {
		if (!(props.Registration?.Device?.DefaultFolderId)) return f.Parent === null;
		return f.Id === props.Registration?.Device?.DefaultFolderId;
	});

	const [addingToBasket, setAddingToBasket] = useState(null);
	const [currentFolder, setCurrentFolder] = useState(rootFolder);

	const inventoryPageSize = 100;
	const [inventoryCurrentPage, setInventoryCurrentPage] = useState(1);
	const inventoryMaxPage = useRef(1);

	const productsUnavailableOffline = props.Inventory?.Products?.filter(p => (p.AvailableOffline === false));

	/**
	 * Find existing items in the basket representing a product.
	 *
	 * @param {Object} product
	 * @param {Integer} targetPrice optional
	 * @return {Array<Object>} `CheckoutBasketItem`-like objects
	 */
	const findExistingProductBasketItems = (product, targetPrice=undefined) => {
		return props.BasketItems.filter(i => {
			const ot = (i.OrderableType === OrderableTypes.Addon);
			const id = (i.Item?.Id === product.Id);
			const price = ((targetPrice === undefined) || (i.Item?.Price === targetPrice));
			return (ot && id && price);
		});
	};


	/**
	 * Adding to the basket.
	 * 
	 * @return {ReactNode}
	 */
	const handleAddToBasket = async (product, price) => {

		setAddingToBasket(product);

		/** 
		 * Explicit decision not to update Qty on existing items and always add a new item
		 * See https://gitlab.heron-web.com/hps/hops/-/work_items/1434
		 */
		try {
			await BasketService.addItems([
				CheckoutBasketItem.construct({
					OrderableType: OrderableTypes.Addon,
					Item: product,
					Price: price,
					Quantity: 1,
					VatProportion: product.VatProportion,
					VatRate: product.VatRate
				})
			]);
		}
		catch (e) {
			// ...
		}

		setAddingToBasket(null);

	};


	/**
	 * Return a Folder object with a given Id
	 * 
	 * @return {Object}
	 */
	const getFolderById = folderId => {
		return props.Registration?.Folders.find(f => (f.Id === folderId));
	};


	/**
	 * Recursively walk back through folder parents to build a breadcrumb trail
	 * 
	 * @return {Array}
	 */
	const getFolderBreadcrumbs = (folder, currentBreadcrumbs = []) => {

		if (folder) {

			// We need to check if the current folder is acting as the root (regardless of if it has its own parent)
			const folderParentFolder = folder.Id !== rootFolder.Id ? folder.Parent : null;

			currentBreadcrumbs.push(folder);
			currentBreadcrumbs = currentBreadcrumbs.concat(getFolderBreadcrumbs(getFolderById(folderParentFolder)));
		}

		return currentBreadcrumbs;
	};

	/**
	 * Get available folders and products. Map them on to a generic object.
	 *
	 * @return {Array}
	 */
	const getInventoryTiles = () => {

		// Create an array of generic Tile objects with common properties
		const inventoryTiles = [];
		const currentFolderId = currentFolder ? currentFolder.Id : null;

		let folderTiles = [];
		let productTiles = [];

		/** Filter to matching products if a barcode string is specified */
		if (BarcodeString) {
			productTiles = props.Inventory?.Products.filter(p => p.Barcodes.includes(BarcodeString));
		}

		/** Or show children of the current folder */
		else {
			folderTiles = props.Registration?.Folders.filter(f => (f.Parent === currentFolderId));
			productTiles = props.Inventory?.Products.filter(p => (p.Folders.includes(currentFolderId)));
		}

		// Add Folders
		folderTiles?.forEach(folder => {
			inventoryTiles.push(
				{
					ButtonText: folder.Name, /* Note: This is inconsistent with Products */
					BackgroundColour: folder.BackgroundColour,
					Id: folder.Id,
					Item: folder,
					Name: folder.Name,
					Order: folder.Order,
					TileType: InventoryGridTileTypes.Folder
				}
			);
		});

		productTiles?.forEach(product => {

			if (!(props.AlwaysOffline && !product.AvailableOffline)) {
				inventoryTiles.push(
					{
						ButtonText: product.ButtonText,
						BackgroundColour: product.BackgroundColour,
						Id: product.Id,
						Item: product,
						Name: product.Name,
						Order: product.Order,
						TileType: InventoryGridTileTypes.Product
					}
				);
			}
		});

		// Order the combined list
		inventoryTiles.sort((tileA, tileB) => {

			// Sort by Order
			if (tileA.Order < tileB.Order) return -1;
			if (tileA.Order > tileB.Order) return 1;

			// Fall through to crude sort by Name
			if (tileA.Name.toLowerCase() < tileB.Name.toLowerCase()) return -1;
			if (tileA.Name.toLowerCase() > tileB.Name.toLowerCase()) return 1;

			return 0;

		});

		// Define the maximum number of pages for the current set of tiles
		inventoryMaxPage.current = Math.ceil(inventoryTiles.length / inventoryPageSize);

		// Paginate the combined list
		const startIndex = (inventoryCurrentPage - 1) * inventoryPageSize;
		const endIndex = startIndex + inventoryPageSize;

		return inventoryTiles.slice(startIndex, endIndex);
	};
	const inventoryTiles = getInventoryTiles();

	// We need to check if the current folder is acting as the root (regardless of if it has its own parent)
	const parentFolder = currentFolder.Id !== rootFolder.Id ? getFolderById(currentFolder?.Parent) : null;
	const breadcrumbs = getFolderBreadcrumbs(currentFolder)?.toReversed();

	const handleBreadcrumbClick = id => {
		setCurrentFolder(getFolderById(id));
	};

	const handleClearBarcodeClick = () => {
		Navigator.navigate("/");
	};

	const handleChangePage = (e, page) => {
		setInventoryCurrentPage(page);
	};

	return (
		<Flex>
			<Paper py={0.75}>
				<Flex
					alignItems="center"
					justifyContent="space-between"
					columnar={!(props.isMobile)}>
					{!BarcodeString && <BreadcrumbBar breadcrumbs={breadcrumbs} onBreadcrumbClick={handleBreadcrumbClick} />}
					<Pagination
						count={inventoryMaxPage.current}
						page={inventoryCurrentPage}
						onChange={handleChangePage}
						showFirstButton
						showLastButton />
				</Flex>
			</Paper>

			<Flex overflowY="auto" width="100%" gap={0.5}>
				{BarcodeString && <Banner
					str={`Please select a product for '${BarcodeString}'.`}
					title="Multiple Products Found"
					severity="info" />}

				<InventoryGrid>

					{/* Add a Back button to allow users to navigate through the tree */}
					{parentFolder && <InventoryGridItemFolder
						accentColour="#333"
						item={parentFolder}
						label={`Back to ${parentFolder?.Name}`}
						tileIcon={<ArrowBack sx={{fontSize: 45}} />}
						onChangeFolder={setCurrentFolder}/>}

					{/* Add a Back button to allow users to exit barcode mode */}
					{BarcodeString && <InventoryGridItemFolder
						accentColour="#333"
						label={`Back`}
						tileIcon={<ArrowBack sx={{fontSize: 45}} />}
						onChangeFolder={handleClearBarcodeClick}/>}

					<EmptyStateGate isEmpty={!inventoryTiles.length}>
						{
							inventoryTiles?.map((inventoryTile, key) => {

								// For a Folder
								if (inventoryTile.TileType === InventoryGridTileTypes.Folder) {
									return (
										<InventoryGridItemFolder
											accentColour={inventoryTile.Item.BackgroundColour}
											disabled={!!addingToBasket}
											key={`${currentFolder?.Id}.folders.${key}`}
											item={inventoryTile.Item}
											label={inventoryTile.ButtonText}
											onChangeFolder={setCurrentFolder} />
									);
								}

								// For a Product
								else if (inventoryTile.TileType === InventoryGridTileTypes.Product) {

									const itemOffline = (props.ReducedFunctionality && !inventoryTile.Item.AvailableOffline);
									const itemsInBasket = findExistingProductBasketItems(inventoryTile.Item);
									const countInBasket = itemsInBasket.reduce((a, b) => (a + b.Quantity), 0);
									const maxQtyExceeded = ((inventoryTile.Item.MaxQuantity !== null) && (countInBasket >= inventoryTile.Item.MaxQuantity));
									const imageSrc = inventoryTile.Item.ImagePath ? `${Api.baseURL}${inventoryTile.Item.ImagePath}` : null;

									return (
										<InventoryGridItemProduct
											backgroundColor={inventoryTile.Item.BackgroundColour}
											disabled={!!addingToBasket}
											key={`${currentFolder?.Id}.products.${key}`}
											imageSrc={imageSrc}
											item={inventoryTile.Item}
											label={inventoryTile.ButtonText}
											loading={(addingToBasket?.Id === inventoryTile.Id)}
											onAddToBasket={handleAddToBasket}
											price={inventoryTile.Item.Price}
											skuUnavailable={maxQtyExceeded || itemOffline}
											skuUnavailableMessage={itemOffline ? "Unavailable Offline" : `Maximum of ${inventoryTile.Item.MaxQuantity} Allowed`} />
									);
								}

								else {
									return null;
								}
							})
						}
					</EmptyStateGate>
				</InventoryGrid>

				{inventoryMaxPage.current > 1 && <Flex
					columnar={true}
					justifyContent="center">
					<Pagination
						count={inventoryMaxPage.current}
						page={inventoryCurrentPage}
						onChange={handleChangePage}
						showFirstButton
						showLastButton />
				</Flex>}

				<Hidden hidden={!(props.AlwaysOffline && productsUnavailableOffline.length)}>
					<String
						color="textSecondary"
						lineHeight="2.5em"
						py={2}
						str={`${productsUnavailableOffline.length} unavailable products are hidden because this device is always offline.`}
						variant="body2" />
				</Hidden>
			</Flex>
		</Flex>
	);

};

export default withBasket(withInventory(withMobile(withReducedFunctionality(withRegistration((ProductInventoryBrowser))))));
