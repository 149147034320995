import dAdminUser from "./dAdminUser.js";
import dLogoutDialog from "./dLogoutDialog.js";

const dLogoutAdmin = () => {

	dAdminUser(null);
	dLogoutDialog(false);

};

export default dLogoutAdmin;
