import {String} from "@hps/hops-react";
import {Localisation} from "@hps/hops-sdk-js";
import React from "react";

import InventoryGridItemBase from "Inventory/InventoryGridItemBase.js";

/**
 * Inventory grid item
 *
 * An item displayed in an inventory grid.
 *
 * @package HOPS
 * @subpackage Inventory
 * @copyright Heritage Operations Processing Limited
 */
const InventoryGridItemProduct = props => {

	const accentColour = props.skuUnavailable ? "#f00" : (props.backgroundColor === "000000" ? null : props.backgroundColor);

	const priceText = (props.priceTextOverride || ((props.price !== null) ? Localisation.formatCurrency(props.price) : ((props.price !== undefined) ? (props.customPriceText || "-") : undefined)));
	const unavailable = (props.soldOut || props.skuUnavailable);
	const skuUnavailableMessage = (props.skuUnavailable ? (props.skuUnavailableMessage || "Not Available") : undefined);
	const soldOutMessage = (props.soldOut ? "Sold Out" : undefined);
	const unavailableMessage = (soldOutMessage || skuUnavailableMessage);
	const label = props.label;
	const subLabel = ((!props.alwaysShowPriceText ? unavailableMessage : priceText) || priceText);

	/**
	 * Adding to basket.
	 *
	 * @param {Integer} price
	 * @return {void}
	 */
	const handleAddToBasket = price => {
		props.onAddToBasket?.(props.item, price);
	};

	return <InventoryGridItemBase
		accentColour={accentColour}
		disabled={(unavailable || props.disabled || props.loading)}
		imageSrc={props.imageSrc}
		loading={props.loading}
		price={props.price}
		onAddToBasket={handleAddToBasket}
		subtitle={subLabel}
		title={label}>

		{/* Unavailable Text */}
		<String
			bold={true}
			color="error"
			str={((unavailable && props.alwaysShowPriceText) ? unavailableMessage : undefined)}
			variant="subtitle1" />

	</InventoryGridItemBase>;

};

export default InventoryGridItemProduct;
