import {Navigator, NumericInput, SnackbarService, String} from "@hps/hops-react";
import bcrypt from "bcryptjs";
import React from "react";

import useQuery from "Hooks/useQuery";

/**
 * Login PIN entry screen
 * 
 * @package HOPS
 * @subpackage Login
 * @copyright Heritage Operations Processing Limited
 */
const LoginPinEntry = props => {

	const query = useQuery();
	const redirect = query.get("redirect");

	const handleSubmitPin = pin => {

		if (bcrypt.compareSync(pin.toString(), props.user.Pin)) {
			props.onLoginSuccess?.(props.user);
			if (redirect) Navigator.navigate(redirect);
		}
		else SnackbarService.snack("Incorrect PIN", "error");

	};

	return (
		<>
			<String
				centre={true}
				gap={0}
				str={[`Hello, ${props.user.Account.Fname}.`, `Enter your PIN number to login.`]} />

			<NumericInput
				formatAsPassword={true}
				label="PIN Code"
				onSubmit={handleSubmitPin} />

		</>
	);

};

export default LoginPinEntry;
