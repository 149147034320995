import {Dialog, withRegistration} from "@hps/hops-react";
import {memo} from "react";

import Permissions from "App/Permissions";
import dAdminUser from "Dispatchers/dAdminUser";
import dSupervisorLoginDialog from "Dispatchers/dSupervisorLoginDialog";
import withAuthUser from "Hoc/withAuthUser";
import withUi from "Hoc/withUi";

import LoginUserSelector from "./LoginUserSelector";

import scss from "./SupervisorLoginDialog.module.scss";

const SupervisorLoginDialog = memo(({AuthUser, Registration, Ui}) => {

	/**
	 * Determine if the user is a "supervisor" - has any of the supervisor permissions
	 * @param {Object} user 
	 * @returns {Boolean} If the user is a "supervisor"
	 */
	const userHasSupervisorPermissions = user => {

		// Remember, this produces an array of strings
		const userPermissionList = Object.keys(user.Permissions);

		// So these have to be strings too
		const supervisorPermissionList = [
			Permissions.P524_RECALL_REFUND_IN_POS.toString(),
			Permissions.P525_QUICK_REFUND_IN_POS.toString(),
			Permissions.P526_CREATE_PROVISIONAL_ORDER_IN_POS.toString(),
			Permissions.P527_CREATE_DEPOSIT_ORDER_IN_POS.toString(),
			Permissions.P528_COLLECT_MOTO_PAYMENT.toString(),
			Permissions.P538_PRICE_ADJUSTMENT_IN_POS.toString()
		];

		const intersection = supervisorPermissionList.filter(pid => userPermissionList.includes(pid));

		return !!intersection.length;
	};


	/** For convenience, filter list to just those users with supervisor permissions */
	const supervisorUsers = Registration.Users.filter(u => u.Id !== AuthUser.Id && userHasSupervisorPermissions(u));

	const handleClose = () => {
		dSupervisorLoginDialog(false);
	};


	const handleSupervisorLogin = user => {
		dAdminUser(user);
		dSupervisorLoginDialog(false);
	};


	return (
		<Dialog
			classNameActions={scss.dialogActions}
			classNameTitle={scss.dialogTitle}
			closeLabel="Close"
			noOkButton={true}
			onClose={handleClose}
			open={Ui.SupervisorLoginDialog}
			title="Supervisor Login">

			<LoginUserSelector
				onLoginSuccess={handleSupervisorLogin}
				orientation="vertical"
				itemSize={{xs: 12, sm: 6}}
				users={(supervisorUsers || [])} />

		</Dialog>
	);

});

export default withAuthUser(withRegistration(withUi(SupervisorLoginDialog)));
