import {SnackbarService, Store} from "@hps/hops-react";
import {CheckoutBasketItem, CheckoutBasketPayment, CheckoutDiscountService, CheckoutService, OrderableTypes, OrderSources, TicketSeatReservationModes} from "@hps/hops-sdk-js";
import moment from "moment/moment";
import pluralize from "pluralize";

import dBasket from "Dispatchers/dBasket.js";
import dBasketAdd from "Dispatchers/dBasketAdd.js";
import dBasketClaimData from "Dispatchers/dBasketClaimData.js";
import dBasketDiscounts from "Dispatchers/dBasketDiscounts.js";
import dBasketGoOffline from "Dispatchers/dBasketGoOffline";
import dBasketIdentity from "Dispatchers/dBasketIdentity.js";
import dBasketLoading from "Dispatchers/dBasketLoading.js";
import dBasketQuestions from "Dispatchers/dBasketQuestions.js";
import dBasketRemove from "Dispatchers/dBasketRemove";
import dBasketReset from "Dispatchers/dBasketReset.js";
import dBasketUpdate from "Dispatchers/dBasketUpdate.js";
import dPayments from "Dispatchers/dPayments";
import dPaymentsAdd from "Dispatchers/dPaymentsAdd";
import dPaymentsRemove from "Dispatchers/dPaymentsRemove";
import dPaymentsReset from "Dispatchers/dPaymentsReset";
import dPaymentsUpdate from "Dispatchers/dPaymentsUpdate";
import dRefund from "Dispatchers/dRefund";
import dSeatReservationDialog from "Dispatchers/dSeatReservationDialog.js";

import ConnectivityService from "./ConnectivityService";


/**
 * Basket service
 *
 * Utilities to aid managing the client-side basket.
 *
 * @package HOPS
 * @subpackage Basket
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class BasketService {

	/**
	 * Add items to the basket.
	 *
	 * Warns the user via snackbars when errors occur.
	 *
	 * @param {Array<Object>} CheckoutBasketItems CheckoutBasketItem-like objects
	 * @param {Boolean} options.dispatchClaimData optional Dispatch result claim data to store (`true`)
	 * @param {Boolean} options.snackOnError optional Show a snackbar on error (`true`)
	 * @return {Promise} Resolves with `false` or API data
	 */
	static async addItems(CheckoutBasketItems, {dispatchClaimData=true, snackOnError=true}={}) {

		const AppOnline = ConnectivityService.appOnline();
		const Basket = Store.getState().Basket;
		const BasketOnline = Basket?.Identity?.OfflineBasketUuid === null;
		const isRefund = Store.getState().Ui.Refund;

		if (!Basket?.Loading) {

			let data;
			dBasketLoading(true);

			try {

				let CheckoutBasketItemsArray = [...CheckoutBasketItems];

				if (isRefund) {

					// Refund the next product item by setting a negative quantity
					CheckoutBasketItemsArray = CheckoutBasketItems?.map(basketItem => {

						if (basketItem.OrderableType === OrderableTypes.Addon) {
							return {...basketItem, Quantity: basketItem.Quantity * -1};
						}
						else {
							SnackbarService.snack("Only products can be directly refunded through the basket.", "warning");
							return null;
						}

					});

					// Un-latch the refund button
					dRefund(false);
				}


				if (AppOnline && BasketOnline) {

					/**
					 * Device online flow.
					 * 
					 * Device must be online, and the basket identity must not be -1
					 * (Which indicates the basket was created while offline)
					 */

					data = await CheckoutService.addBasketItems(
						CheckoutBasketItemsArray,
						Store.getState().Basket?.Identity?.Id,
						OrderSources.Pos,
						true,
						Store.getState().Registration.Org.Id,
					)
						.catch(e => {
							SnackbarService.snack(`Error adding to basket: ${e.Message}`, "error");
						});

					dBasketIdentity(data?.Basket?.Id, data?.Basket?.Expiry);
					dBasketAdd(CheckoutBasketItemsArray.filter(i => data?.Claims?.includes(i.Uuid)));
					dBasketDiscounts((data?.Basket?.Discounts || []));
					dBasketQuestions((data?.Basket?.Questions || null));
					if (dispatchClaimData) dBasketClaimData({Items: CheckoutBasketItemsArray, Result: data});

					if ((data && (data.Claims?.length === 1)) && (CheckoutBasketItemsArray.length === 1)) {
						const item = CheckoutBasketItemsArray[0];
						if (TicketSeatReservationModes.allowsCustomerSelection(CheckoutBasketItem.getSeatReservationMode(item)) || TicketSeatReservationModes.allowsRailwaySelection(CheckoutBasketItem.getSeatReservationMode(item))) {
							// Single item, throw up the Seat Reservation dialog
							dSeatReservationDialog(item.Uuid);
						}
					}
					else if (data && CheckoutBasketItemsArray.some(item => TicketSeatReservationModes.allowsCustomerSelection(CheckoutBasketItem.getSeatReservationMode(item)))) {
						// Multiple items, warn the operator to pick seats in the basket.
						SnackbarService.snack("Tickets Added - Please complete the Seat Reservations via the basket.", "info");
					}
				}
				else {

					// Device Offline Flow

					/**
					 * Set our identity to -1 to show we're an offline basket now.
					 * The device went offline between items being added.
					 */
					dBasketGoOffline();
					dBasketAdd(CheckoutBasketItemsArray);
					dBasketQuestions(null);

				}

			}
			catch (e) {

				dBasketLoading(false);
				if (snackOnError) SnackbarService.snack(e);

				throw e;

			}

			dBasketLoading(false);
			return data;

		}

		else {

			SnackbarService.snack("Please wait until the existing basket operation completes.", "warning");
			return false;

		}

	}


	/**
	 * Add payments.
	 *
	 * Warns the user via snackbars when errors occur.
	 *
	 * @param {Array<Object>} PaymentItems PaymentItems-like objects
	 * @param {Boolean} options.snackOnError optional Show a snackbar on error (`true`)
	 * @return {Promise} Resolves with `false` or API data
	 */
	static async addPayments(PaymentItems, {snackOnError=true}={}) {

		const AppOnline = ConnectivityService.appOnline();
		const Basket = Store.getState().Basket;
		const BasketOnline = Basket?.Identity?.OfflineBasketUuid === null;

		if (!Basket?.Loading) {

			let data;
			dBasketLoading(true);

			try {

				if (AppOnline && BasketOnline) {

					/**
					 * Device online flow.
					 * 
					 * Device must be online, and the basket identity must not be -1
					 * (Which indicates the basket was created while offline)
					 */

					data = await CheckoutService.addPaymentItems(PaymentItems, Basket?.Identity?.Id);
					dBasketIdentity(data?.Basket?.Id, data?.Basket?.Expiry);
					dPaymentsAdd(PaymentItems.filter(i => data?.Basket?.Payments?.some(p => p.Uuid === i.Uuid)));
				}
				else {

					// Device Offline Flow

					/**
					 * Set our identity to -1 to show we're an offline basket now.
					 * The device went offline between items being added.
					 */
					dBasketGoOffline();
					dPaymentsAdd(PaymentItems.map(p => {
						return {
							...p,
							PaymentProcessorData: {
								...p.PaymentProcessorData,
								...CheckoutBasketPayment.getPaymentApiData(p)
							}
						};
					}));
				}

			}
			catch (e) {

				dBasketLoading(false);
				if (snackOnError) SnackbarService.snack(e);

				throw e;

			}

			dBasketLoading(false);
			return data;

		}

		else {
			SnackbarService.snack("Please wait until the existing basket operation completes.", "warning");
			return false;
		}

	}


	/**
	 * Remove items from the basket including on the server.
	 *
	 * Warns the user via snackbars when errors occur.
	 *
	 * @param {Array<Object>} CheckoutBasketItems CheckoutBasketItem-like objects
	 * @param {Boolean} options.snackOnError optional Show a snackbar on error (`true`)
	 * @return {Promise} Resolves with `false` or API data
	 */
	static async removeItems(CheckoutBasketItems, {snackOnError=true}={}) {

		const AppOnline = ConnectivityService.appOnline();
		const Basket = Store.getState().Basket;
		const BasketOnline = Basket?.Identity?.OfflineBasketUuid === null;

		if (!Basket?.Loading) {

			let basket;
			dBasketLoading(true);

			/**
			 * Remove the items now
			 */
			try {

				if (AppOnline && BasketOnline) {

					/**
					 * We need to cache the discounts we currently have applied 
					 * so we can detect removals and show a warning message to 
					 * the user later on
					 */
					const currentDiscounts = [...(Store.getState().Basket?.Discounts || [])];

					/**
					 * Device online flow.
					 * 
					 * Device must be online, and the basket identity must not be -1
					 * (Which indicates the basket was created while offline)
					 */

					basket = await CheckoutService.removeBasketItems(
						CheckoutBasketItems,
						Store.getState().Basket?.Identity?.Id,
						true
					);

					/**
					 * Dispatch main basket state updates
					 */
					dBasketIdentity(basket.Id, basket.Expiry);
					dBasket(Store.getState().Basket?.Items?.filter(i => basket.ItemUuids?.includes?.(i.Uuid)));
					dBasketQuestions((basket.Questions || null));

					/**
					 * Dispatch the updated discount codes
					 */
					const updatedDiscounts = (basket.Discounts || []);
					dBasketDiscounts(updatedDiscounts);

					/**
					 * Display a warning, when required, if discounts were removed
					 */
					this.warnUserDiscountCodesRemoved(updatedDiscounts, currentDiscounts, "Item removed from basket");
				}
				else {

					// Device Offline Flow

					/**
					 * Set our identity to -1 to show we're an offline basket now.
					 * The device went offline between items being modified.
					 */
					dBasketGoOffline();
					dBasketRemove(CheckoutBasketItems);
					dBasketQuestions(null);

				}

			}
			catch (e) {

				dBasketLoading(false);

				if (snackOnError) {
					SnackbarService.snack(e, "error");
				}

				throw e;

			}

			dBasketLoading(false);
			return basket;

		}

		else {
			SnackbarService.snack("Please wait until the existing basket operation completes.", "warning");
			return false;
		}

	}


	/**
	 * Remove items from the payments ledger including on the server.
	 *
	 * Warns the user via snackbars when errors occur.
	 *
	 * @param {Array<Object>} PaymentItems PaymentItems-like objects
	 * @param {Boolean} options.snackOnError optional Show a snackbar on error (`true`)
	 * @return {Promise} Resolves with `false` or API data
	 */
	static async removePayments(PaymentItems, {snackOnError=true}={}) {

		const AppOnline = ConnectivityService.appOnline();
		const Basket = Store.getState().Basket;
		const BasketOnline = Basket?.Identity?.OfflineBasketUuid === null;

		if (!Basket?.Loading) {

			let payments;
			dBasketLoading(true);

			try {

				if (AppOnline && BasketOnline) {

					/**
					 * Device online flow.
					 * 
					 * Device must be online, and the basket identity must not be -1
					 * (Which indicates the basket was created while offline)
					 */

					payments = await CheckoutService.removePaymentItems(
						PaymentItems,
						Basket.Identity?.Id,
						true
					);

					dBasketIdentity(payments?.Id, payments?.Expiry);
					dPayments(Basket.Payments.filter(i => payments.ItemUuids?.includes?.(i.Uuid)));

				}
				else {

					// Device Offline Flow

					/**
					 * Set our identity to -1 to show we're an offline basket now.
					 * The device went offline between items being modified.
					 */
					dBasketGoOffline();
					dPaymentsRemove(PaymentItems);

				}

			}
			catch (e) {

				dBasketLoading(false);

				if (snackOnError) {
					SnackbarService.snack(e, "error");
				}

				throw e;

			}

			dBasketLoading(false);
			return payments;

		}

		else {
			SnackbarService.snack("Please wait until the existing basket operation completes.", "warning");
			return false;
		}

	}


	/**
	 * Cancel (zero-value) item from the payments ledger including on the server.
	 * Item must remain in the basket, with zero value, to show on receipts and
	 * so that Stripe Connect HOPS fees are still charged on any refunded transactions.
	 *
	 * Warns the user via snackbars when errors occur.
	 *
	 * @param {Object} item PaymentItem-like object
	 * @param {Boolean} options.snackOnError optional Show a snackbar on error (`true`)
	 * @return {Promise} Resolves with `false` or API data
	 */
	static async cancelPayment(payment, {snackOnError=true}={}) {

		const AppOnline = ConnectivityService.appOnline();
		const Basket = Store.getState().Basket;
		const BasketOnline = Basket?.Identity?.OfflineBasketUuid === null;

		if (!Basket?.Loading) {

			let payments;
			dBasketLoading(true);

			try {

				if (AppOnline && BasketOnline) {

					/**
					 * Device online flow.
					 * 
					 * Device must be online, and the basket identity must not be -1
					 * (Which indicates the basket was created while offline)
					 */

					payments = await CheckoutService.cancelPaymentItems(
						payment.Uuid,
						Basket.Identity?.Id
					);

					dBasketIdentity(payments?.Id, payments?.Expiry);

					dPayments(
						Basket.Payments.filter(
							i => payments.Payments?.map(payment => payment.Uuid).includes?.(i.Uuid)
						)
							.map(basketPayment => ({
								...basketPayment,
								PaymentCanRefund: false,
								PaymentHasRefunded: true,
								PaymentImmediateCharge: false,
								TenderedAmount: 0,
								Value: 0
							}))
					);

				}
				else {

					// Device Offline Flow

					dBasketGoOffline();
					dPaymentsUpdate(payment.Uuid, {
						...payment,
						PaymentCanRefund: false,
						PaymentHasRefunded: true,
						PaymentImmediateCharge: false,
						TenderedAmount: 0,
						Value: 0
					});

				}

			}
			catch (e) {

				dBasketLoading(false);

				if (snackOnError) {
					SnackbarService.snack(e, "error");
				}

				throw e;

			}

			dBasketLoading(false);
			return payments;

		}

		else {
			SnackbarService.snack("Please wait until the existing basket operation completes.", "warning");
			return false;
		}

	}


	/**
	 * Update the price of an item in the basket.
	 *
	 * @param {Object} item CheckoutBasketItem-like item object
	 * @param {Integer} newPrice Updated item quantity
	 * @return {Promise}
	 */
	static async updateItemPrice(item, newPrice, reason, supervisorId, supervisorPin) {

		const AppOnline = ConnectivityService.appOnline();
		const Basket = Store.getState().Basket;
		const BasketOnline = Basket?.Identity?.OfflineBasketUuid === null;

		if (!Basket?.Loading) {

			dBasketLoading(true);

			try {

				if (AppOnline && BasketOnline) {
					const basket = await CheckoutService.updateBasketItemPrice(
						item.Uuid,
						newPrice,
						reason,
						Store.getState().Basket?.Identity?.Id,
						supervisorId,
						supervisorPin
					);

					if (!basket?.Error) {
						dBasketIdentity(basket?.Id, basket?.Expiry);
						dBasketUpdate(item.Uuid, {Price: newPrice});
						dBasketDiscounts((basket?.Discounts || []));
					}
					else {

						/**
						 * When an error occurred, we dispatch "fake" basket
						 * addition data in the format returned by the "add to
						 * basket" endpoint so the added to basket error dialog
						 * appears and the user can review the issue.
						 *
						 * (This will only occur when increasing quantity.)
						 */
						dBasketClaimData({
							Items: [item],
							Result: {
								Claims: [],
								Errors: [
									{
										Uuid: item.Uuid,
										Code: basket.Error
									}
								]
							}
						});

					}

				}
				else {

					// Device Offline Flow

					/**
					 * Set our identity to -1 to show we're an offline basket now.
					 * The device went offline between items being modified.
					 */
					dBasketGoOffline();
					dBasketUpdate(item.Uuid, {Quantity: newPrice});

				}

			}
			catch (e) {
				SnackbarService.snack("You are not authorised to perform that action.", "error");
			}

			dBasketLoading(false);

		}
		else {
			SnackbarService.snack("Please wait until the existing basket operation completes.", "warning");
			return;
		}

	}


	/**
	 * Update the quantity of an item in the basket.
	 *
	 * @param {Object} item CheckoutBasketItem-like item object
	 * @param {Integer} newQty Updated item quantity
	 * @return {Promise}
	 */
	static async updateItemQty(item, newQty) {

		const AppOnline = ConnectivityService.appOnline();
		const Basket = Store.getState().Basket;
		const BasketOnline = Basket?.Identity?.OfflineBasketUuid === null;

		if (newQty === 0) {
			this.removeItems([item]);
			return;
		}

		if (!Basket?.Loading) {

			dBasketLoading(true);

			try {

				if (AppOnline && BasketOnline) {
					const basket = await CheckoutService.updateBasketItemQuantity(
						item.Uuid,
						newQty,
						Store.getState().Basket?.Identity?.Id
					);

					if (!basket?.Error) {
						dBasketIdentity(basket?.Id, basket?.Expiry);
						dBasketUpdate(item.Uuid, {Quantity: newQty});
						dBasketDiscounts((basket?.Discounts || []));
					}
					else {

						/**
						 * When an error occurred, we dispatch "fake" basket
						 * addition data in the format returned by the "add to
						 * basket" endpoint so the added to basket error dialog
						 * appears and the user can review the issue.
						 *
						 * (This will only occur when increasing quantity.)
						 */
						dBasketClaimData({
							Items: [item],
							Result: {
								Claims: [],
								Errors: [
									{
										Uuid: item.Uuid,
										Code: basket.Error
									}
								]
							}
						});

					}

				}
				else {

					// Device Offline Flow

					/**
					 * Set our identity to -1 to show we're an offline basket now.
					 * The device went offline between items being modified.
					 */
					dBasketGoOffline();
					dBasketUpdate(item.Uuid, {Quantity: newQty});

				}

			}
			catch (e) {
				SnackbarService.snack(e);
			}

			dBasketLoading(false);

		}
		else {
			SnackbarService.snack("Please wait until the existing basket operation completes.", "warning");
			return;
		}

	}


	/**
	 * Remove a discount code from the basket.
	 *
	 * Affects all discount claims using the code.
	 *
	 * @param {String} code Discount code
	 * @param {Boolean} options.snackOnError optional Show a snackbar on error (`true`)
	 * @return {Promise}
	 */
	static async removeDiscountCode(code, {snackOnError=true}={}) {

		const AppOnline = ConnectivityService.appOnline();
		const Basket = Store.getState().Basket;
		const BasketOnline = Basket?.Identity?.OfflineBasketUuid === null;

		if (!Basket?.Loading) {

			dBasketLoading(true);

			/**
			 * Remove the discount code now
			 */
			try {

				if (AppOnline && BasketOnline) {

					/**
					 * Find discount claims that are using this code
					 */
					const claims = Basket?.Discounts.filter(d => (d.Discount.Code === code)).map(d => d.Discount.Claim);

					/**
					 * Make the API call
					 */
					const remainingDiscounts = await CheckoutDiscountService.removeDiscountCode(Basket?.Identity?.Id, claims);

					/**
					 * We're done!
					 */
					dBasketDiscounts((remainingDiscounts || []));
					SnackbarService.snack(`Discount code ${code} removed.`, "success");
				}
				else {

					dBasketGoOffline();

				}

			}
			catch (e) {

				if (snackOnError) {
					SnackbarService.snack(e);
				}

			}

			dBasketLoading(false);

		}
		else {
			SnackbarService.snack("Please wait until the existing basket operation completes.", "warning");
			return;
		}

	}


	/**
	 * Clear the basket.
	 *
	 * Does not wait for the network call to clear the server basket 
	 * to return as it doesn't matter to us when it can't be cleared.
	 *
	 * @return {void}
	 */
	static clearItems() {

		const AppOnline = ConnectivityService.appOnline();
		const Basket = Store.getState().Basket;

		/**
		 * We want to clear the basket on the server when we have a 
		 * basket identity; if an error occurs, we ignore it silently 
		 * as it only means the items continue to be in the server basket 
		 * until they naturally expire, but we still want to allow the 
		 * operator to create a new basket straightaway.
		 */
		if (AppOnline && Basket?.Identity?.Id) CheckoutService.removeBasketItems(Basket.Items, Basket.Identity?.Id).catch(() => undefined);

		/**
		 * Reset the basket state
		 */
		dBasketReset();

	}


	/**
	 * Clear the payments.
	 *
	 * Does not wait for the network call to clear the server payments 
	 * to return as it doesn't matter to us when it can't be cleared.
	 *
	 * @return {void}
	 */
	static clearPayments() {

		const Basket = Store.getState().Basket;
		const AppOnline = ConnectivityService.appOnline();

		/**
		 * We want to clear the payments on the server when we have a 
		 * payments identity; if an error occurs, we ignore it silently 
		 * as it only means the items continue to be in the server basket 
		 * until they naturally expire, but we still want to allow the 
		 * operator to create a new basket straight away.
		 */
		if (AppOnline && Basket?.Identity?.Id) CheckoutService.removePaymentItems(Basket.Payments, Basket.Identity.Id).catch(() => undefined);

		/**
		 * Reset the payments state
		 */
		dPaymentsReset();

	}


	/**
	 * Warn the user that discount codes have been removed from the basket.
	 *
	 * @param {Array<Object>} discounts Currently applied discount claim objects
	 * @param {Array<Object>} prevDiscounts Previously applied claim objects
	 * @param {String} prefix Message to prepend to the warning
	 * @return {void}
	 */
	static warnUserDiscountCodesRemoved(discounts, prevDiscounts, prefix="") {

		const removed = [];

		/**
		 * Map our claim objects to the discount codes they refer to
		 */
		const codes = discounts.map(claim => claim?.Discount?.Code);
		const prevCodes = prevDiscounts.map(claim => claim?.Discount?.Code);

		/**
		 * Discover which codes have gone
		 */
		for (const code of prevCodes) {
			if (!codes.includes(code) && !removed.includes(code)) {
				removed.push(code);
			}
		}

		/**
		 * Have we removed any codes?
		 */
		if (removed?.length > 0) {

			const codesTerm = pluralize("code", removed.length);

			SnackbarService.snack(
				[
					(prefix ? `${prefix} - ` : ``),
					`${(!prefix ? "Discount" : "discount")} ${codesTerm} no longer being used:`,
					`${removed.join(", ")}.`,
					`\nRe-enter the discount ${codesTerm} if you add another qualifying item to the basket.`
				].join(" "),
				"default",
				{
					style: {
						whiteSpace: "pre-line"
					}
				}
			);

		}

	}


	static calculateOfflineBasketExpiry() {
		return Math.floor(moment(new Date()).add(15, "m").toDate() / 1000);
	}

	static isBasketOffline() {
		return !(Store.getState().Basket?.Identity?.OfflineBasketUuid === null);
	}

}

export default BasketService;
