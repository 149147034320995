import {Button, Flex} from "@hps/hops-react";
import React, {useState} from "react";

import LoginPinEntry from "./LoginPinEntry.js";
import LoginUserSelectorGrid from "./LoginUserSelectorGrid.js";

/**
 * User selector view on the login screen
 *
 * Enables quick login as a registered user.
 * 
 * @package HOPS
 * @subpackage Login
 * @copyright Heritage Operations Processing Limited
 */
const LoginUserSelector = props => {

	const [selectedUser, setSelectedUser] = useState(null);
	const handleUserSelectCancel = () => setSelectedUser(null);
	const handleUserSelect = ({Id}) => setSelectedUser(Id);

	const user = (selectedUser && props.users.find(u => (u.Id === selectedUser)));

	const sortedUsers = () => {
		const username = usr => `${usr.Account.Fname} ${usr.Account.Sname}`;
		return props.users?.sort((a, b) => ((username(a) > username(b)) ? 1 : -1));
	};

	const renderPinEntry = user => {
		return (
			<LoginPinEntry
				onCancel={handleUserSelectCancel}
				onLoginSuccess={props.onLoginSuccess}
				user={user} />
		);
	};

	const renderSelectionGrid = () => {
		return (
			<LoginUserSelectorGrid
				onNewUser={props.onNewUser}
				onUserSelect={handleUserSelect}
				itemSize={props.itemSize}
				users={sortedUsers()} />
		);
	};

	return (
		<Flex gap={2} height="100%" minHeight="0rem" width={(!user ? "100%" : undefined)}>
			{(!user ? renderSelectionGrid() : renderPinEntry(user))}
			{selectedUser && <Button label="Return to User List" onClick={() => setSelectedUser(null)} />}
		</Flex>
	);

};

export default LoginUserSelector;
