import {Dialog, Navigator, String} from "@hps/hops-react";
import React from "react";
import {connect} from "react-redux";

import dLogout from "Dispatchers/dLogout.js";
import dLogoutAdmin from "Dispatchers/dLogoutAdmin";
import dLogoutDialog from "Dispatchers/dLogoutDialog.js";
import withAuthUser from "Hoc/withAuthUser";
import withBasket from "Hoc/withBasket.js";

/**
 * Logout dialog
 * 
 * @package HOPS
 * @subpackage Login
 * @copyright Heritage Operations Processing Limited
 */
const LogoutDialog = props => {

	const handleClose = () => dLogoutDialog(false);

	const handleSubmit = () => {

		if (props.supervised) {
			dLogoutAdmin();
		}
		else {
			dLogout();
		}

		Navigator.navigate("/");
	};

	return (
		<Dialog
			onClose={handleClose}
			onOk={handleSubmit}
			okLabel="Logout"
			open={props.Ui.LogoutDialog}
			str="Are you sure you want to logout?"
			title="Logout"
			width="xs">
			<String
				color="textSecondary"
				str="Are you sure you want to logout?" />
			{props.supervised && <String
				color="info"
				str="This will return control to the original operator without clearing the basket." />}
			{(!!(props.BasketItems?.length) && !props.supervised) && <String
				color="error"
				str="Logging out will void all items in the basket." />}
		</Dialog>
	);

};

export default connect(({Ui}) => ({Ui}))(withAuthUser(withBasket(LogoutDialog)));
