import Grid from "@mui/material/Grid";

import scss from "./InventoryGrid.module.scss";

const InventoryGrid = ({children}) => {
	return (
		<div className={scss.container}>
			<Grid container >
				{children}
			</Grid>
		</div>
	);
};

export default InventoryGrid;
