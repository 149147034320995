/* eslint-disable no-unused-vars */
import {Banner, Button, Flex, Hidden, Loadable, NumericInput, SnackbarService, String, TextField} from "@hps/hops-react";
import {CheckoutBasketItem, Localisation} from "@hps/hops-sdk-js";
import bcrypt from "bcryptjs";
import React, {useRef, useState} from "react";

import withAuthUser from "Hoc/withAuthUser";
import BasketService from "Services/BasketService";

const PriceAdjustmentAction = ({AdminUser, AuthUser, onComplete, targetBasketItem}) => {

	const reasonInputRef = useRef();

	const [loading, setLoading] = useState(false);
	const [pinInput, setPinInput] = useState(null);
	const [reasonInput, setReasonInput] = useState();

	const handleSubmitPin = pin => {

		const authorisingUser = AdminUser ?? AuthUser;

		if (bcrypt.compareSync(pin.toString(), authorisingUser.Pin)) {
			setPinInput(pin.toString());
		}
		else SnackbarService.snack("Invalid PIN", "error");

	};

	const handleProportionalChange = decimalChange => {

		const newPrice = Math.floor(decimalChange * targetBasketItem.PriceListed);

		handleUpdateItemPrice(newPrice);

	};

	const handleUpdateItemPrice = async price => {

		/** Check the validity of the input */
		if (!reasonInputRef?.current?.reportValidity?.()) {
			return;
		}

		if (price === targetBasketItem.Price) {
			SnackbarService.snack(`No action to take - adjusted price is the same as the current price.`, "info");
			onComplete?.();
			return;
		}

		setLoading(true);

		try {

			await BasketService.updateItemPrice(
				targetBasketItem,
				price,
				reasonInput,
				AdminUser ? AdminUser.Id : AuthUser.Id,
				pinInput
			);

			SnackbarService.snack(`Price adjusted to ${Localisation.formatCurrency(price)}`, "success");

			onComplete?.();

		}
		catch (e) {
			SnackbarService.snack(e);
		}

		setLoading(false);

	};


	return (
		<Flex>
			<String bold={true} str={`Price Adjustment for ${CheckoutBasketItem.getUiLabel(targetBasketItem)}`} variant="h6" />

			<Loadable loading={loading}>
				<Hidden hidden={!!pinInput}>
					<Flex>
						<Banner severity="warning" str="Enter your PIN code to authorise this change." />
						<NumericInput
							formatAsPassword={true}
							label="PIN Code"
							onSubmit={handleSubmitPin} />
					</Flex>
				</Hidden>
				<Hidden hidden={!pinInput}>
					<Flex>
						<Banner severity="info" str="Enter the new price per item (not total)." />
						<NumericInput
							label="New Price"
							onSubmit={handleUpdateItemPrice}
							formatAsCurrency={true}>

							<form onSubmit={handleUpdateItemPrice}>
								<Flex>
									<TextField
										inputRef={reasonInputRef}
										label="Reason for price adjustment"
										maxLength={250}
										onChange={setReasonInput}
										required
										value={reasonInput} />
								</Flex>
							</form>

							{targetBasketItem && <Hidden hidden={!targetBasketItem?.PriceListed}>
								<Flex justifyContent="space-between" columnar={true}>
									<Button
										onClick={() => handleProportionalChange(1)}
										label="Original Price"
										variant="contained" />

									<Button
										onClick={() => handleProportionalChange(0.95)}
										label={`${Localisation.formatCurrency(0.95 * targetBasketItem.PriceListed)} (-5%)`}
										variant="contained" />

									<Button
										onClick={() => handleProportionalChange(0.9)}
										label={`${Localisation.formatCurrency(0.9 * targetBasketItem.PriceListed)} (-10%)`}
										variant="contained" />
								</Flex>
								<Flex justifyContent="space-between" columnar={true}>
									<Button
										onClick={() => handleProportionalChange(0.8)}
										label={`${Localisation.formatCurrency(0.8 * targetBasketItem.PriceListed)} (-20%)`}
										variant="contained" />

									<Button
										onClick={() => handleProportionalChange(0.5)}
										label={`${Localisation.formatCurrency(0.5 * targetBasketItem.PriceListed)} (-50%)`}
										variant="contained" />

									<Button
										onClick={() => handleProportionalChange(0)}
										label={`${Localisation.formatCurrency(0)} (Free)`}
										variant="contained" />
								</Flex>
							</Hidden>}

						</NumericInput>
					</Flex>
				</Hidden>
			</Loadable>
		</Flex>
	);

};

export default withAuthUser(PriceAdjustmentAction);
